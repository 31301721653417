  /* Tooltip text */
  .tooltip-text {
    opacity: 0;
    font-size: 13px;
    white-space:nowrap;
    background-color: #434343;
    color: #fff;
    text-align: center;
    font-family: 'overpass', 'sans-serif';
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
   
    
    position: fixed;
    z-index: 1100;
  }
   
  .tooltip-text::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #434343 transparent transparent transparent;
  }
   
