@import '@fontsource/league-gothic/index.css';
@import '@fontsource/overpass/index.css';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '/src/app/_variables.scss';
@import '/src/app/modules/shared/directives/tooltip/tooltip.directive.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 !important;
  font-family: 'overpass', sans-serif;
}

@layer components {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'overpass', cursive;
  }

  h1 {
    @apply text-5xl;
  }

  h2 {
    @apply text-3xl;
  }

  h3 {
    @apply text-xl;
  }

  button {
    &.primary {
      @apply bg-ts-purple-1 rounded-full text-white px-8 py-2;

      &:disabled {
        @apply opacity-50 cursor-not-allowed;
      }
    }

    &.secondary {
      @apply bg-transparent rounded-full text-ts-purple-1 px-8 py-2 border border-ts-purple-1;
    }

    &.tertiary {
      @apply flex flex-row items-center gap-2 text-ts-purple-1;
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }

  label {
    @apply block text-sm font-medium leading-6 text-ts-grey-1;
  }

  input {
    &.regular {
      @apply my-2 m-auto h-10 rounded-md w-full pl-3 border-ts-grey-2 font-normal text-ts-body;
    }
  }

  select {
    @apply my-2 block w-full h-10 rounded-md shadow-none  border-ts-grey-2 py-1.5 pl-3 pr-10 text-body font-normal focus:ring-2 focus:ring-purple sm:text-sm sm:leading-6 disabled:opacity-70;
  }
}

@layer utilities {
  .bg-primary-gradient {
    background: linear-gradient(180deg, #2e0f38 0%, #8051a5 100%);
  }

  .nav-active-link {
    border-bottom: 2px solid #151216;
    color: #332525;
    font-weight: bold;
  }

  .notification-neutral {
    border: 1px solid #8f6730;
    background-color: #fafec9;
    color: #8f6730;
  }

  .notification-success {
    border: 1px solid #accebc;
    background-color: #d5e6de;
    color: #183524;
  }

  .notification-error {
    border: 1px solid #e7b1b6;
    background-color: #f3d8da;
    color: #511a1e;
  }

  .notification-info {    
    background-color: #fff;

    @apply text-ts-body border-ts-grey-3;
  }  
}

/* ng-select */
.ng-select.ng-select-disabled > .ng-select-container {
  @apply bg-ts-grey-4 border-ts-grey-5 #{!important};
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
  display: none;
}

.ng-select-multiple {
  .ng-value {
    @apply bg-ts-grey-5 text-ts-grey-1 #{!important};
  }
}

.ng-option:empty {
  @apply hidden #{!important};
}

.campaign-select {
  .ng-dropdown-header {
    border: 0 !important;
  }
}

/* radio */
input[type='radio'] {
  width: 14px;
  height: 14px;
  appearance: none;
  background-clip: content-box;
  background-color: white;
  @apply border-ts-purple-1 #{!important};

  &:checked {
    background-image: none !important;
    padding: 2px;
    @apply bg-ts-purple-1;
    @apply border-ts-purple-1;
  }
}

/**
  Badges
  ===============
  */

.badge-sm {
  @apply rounded px-2.5 py-0.5;
}

.badge {
  @apply rounded px-4 py-2;
}

.badge-yellow {
  color: var(--badge-text-yellow);
  background-color: var(--badge-bg-yellow);
}

.badge-red {
  color: var(--badge-text-red);
  background-color: var(--badge-bg-red);
}

.badge-green {
  color: var(--badge-text-green);
  background-color: var(--badge-bg-green);
}

.disable-scroll {
  overflow: hidden;
  height: 100vh;
}