:root {
  --red-600: rgba(219, 48, 42, 1);

  /**
     * Badges
     */
  --badge-text-grey: #141414;
  --badge-bg-grey: #f5f5f5;

  --badge-text-yellow: #7a2e0e;
  --badge-bg-yellow: #fef0c7;

  --badge-text-red: #7a271a;
  --badge-bg-red: #fee4e2;

  --badge-text-green: #074d31;
  --badge-bg-green: #dcfae6;
}
